import { logOut } from "../../utils";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getMenu, selectMenu } from "../../store/slice/manager/menu";

import styles from "./style/Navigation.module.scss";
import {
  MdTimeline,
  MdFactCheck,
  MdOutlineApi,
  MdManageAccounts,
  MdMiscellaneousServices,
  MdBusiness,
  MdDashboard,
  MdOutlineMenuBook,
} from "react-icons/md";
import { BiGrid } from "react-icons/bi";
import { ImCircleLeft, ImCircleRight } from "react-icons/im";
import Button from "../Utilities/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLeftNavigate,
  editModeToggleHandler,
  toggleOn,
  toggleOff,
} from "../../store/slice/navigate";
import { selectPermissionSettings } from "../../store/slice/permissionSettings";
import { selectEnterprise } from "../../store/slice/enterpriseLists";
// import { data } from "autoprefixer";

const LeftNavigation = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { menu } = useSelector(selectMenu);
  const { auth } = useSelector(selectPermissionSettings);

  const { isEnterprise } = useSelector(selectEnterprise);

  const [isToggleOn, setIsToggleOn] = useState(false);
  const [menuAdminIndex, setMenuAdminIndex] = useState(0);
  const { navigateEditMode } = useSelector(selectLeftNavigate);
  const toggleLeftNavigation = useSelector(selectLeftNavigate);
  const leftNavigationShow = toggleLeftNavigation.toggleOpen;

  useEffect(() => {
    dispatch(getMenu());
  }, [dispatch]);

  const handleShowList = (index) => {
    setMenuAdminIndex(index);
    if (index === 0) {
      if (isToggleOn === false) {
        setIsToggleOn(true);
      } else if (isToggleOn === true && menuAdminIndex === index) {
        setIsToggleOn(false);
      }
    }
  };

  const handleShowLeftBar = () => {
    if (leftNavigationShow === false) {
      dispatch(toggleOn());
    } else {
      dispatch(toggleOff());
    }
  };

  // useEffect(() => {
  //   window.matchMedia('(max-width: 1024px)').addEventListener('change', dispatch(toggleOff()));
  // }, [dispatch]);

  const menuAdmin = [
    {
      id: 1,
      name: "服務管理",
      icon: <MdMiscellaneousServices className={styles.serviceIcon} />,
      link: "#",
      submenu: menu,
    },
    {
      id: 2,
      name: "企業帳號系統管理",
      icon: <MdBusiness className={styles.serviceIcon} />,
      link: "/admin/company-system",
    },
    {
      id: 3,
      name: "Workspace",
      icon: <MdDashboard className={styles.serviceIcon} />,
      link: "/admin/workspace",
    },
  ];

  const menuTDP = [
    {
      id: 0,
      name: "總覽",
      icon: <MdDashboard className={styles.serviceIcon} />,
      link: "/dashboard/tdp/menu",
      group: "menu",
    },
    {
      id: 1,
      name: "企業生命週期",
      icon: <MdTimeline className={styles.serviceIcon} />,
      group: "life-cycle",
      link: "/dashboard/tdp/life-cycle",
      submenu: menu,
    },
    {
      id: 2,
      name: "PAEI",
      group: "PAEI-setting",
      icon: <MdFactCheck className={styles.serviceIcon} />,
      link: "/dashboard/tdp/PAEI-setting/practical",
    },
    {
      id: 3,
      name: "人才九宮格",
      group: "personal-talent",
      icon: <BiGrid className={styles.serviceIcon} />,
      link: "/dashboard/tdp/personal-talent",
    },
    {
      id: 4,
      name: "ASK",
      group: "ask",
      icon: <MdOutlineApi className={styles.serviceIcon} />,
      link: "/dashboard/tdp/ask/attitude",
    },
    {
      id: 5,
      name: "企業主管理",
      icon: <MdManageAccounts className={styles.serviceIcon} />,
      link: "#",
    },
    {
      id: 6,
      name: "企業案例",
      group: "enterprise",
      icon: <MdOutlineMenuBook className={styles.serviceIcon} />,
      link: "/dashboard/tdp/enterprise",
    },
  ];

  const navigate = useNavigate();

  const handleClickEnterpriseLink = (e, to) => {
    if (to === "/dashboard/tdp/enterprise") {
      return;
    }
    if (isEnterprise) {
      e.preventDefault();
      const userConfirmed = window.confirm("是否離開編輯畫面？");

      if (userConfirmed) {
        navigate(to);
      }
    }
  };

  return (
    <>
      {leftNavigationShow ? (
        <ImCircleLeft
          cursor="pointer"
          onClick={handleShowLeftBar}
          className={styles.leftIcon}
        />
      ) : (
        <ImCircleRight
          cursor="pointer"
          onClick={handleShowLeftBar}
          className={styles.rightIcon}
        />
      )}
      <div
        className={styles.leftNavigation}
        style={{ display: leftNavigationShow ? "block" : "none" }}
      >
        <div className={styles.title}>KEYSTONE 中控系統</div>

        <div className={styles.naviTop}>
          {auth?.roles.find((item) => item === "workspace.admin") &&
            menuAdmin.map((data, index) => (
              <div key={index} className={styles.list}>
                <Link
                  to={
                    menuAdminIndex !== 0 && index === 0 ? "/admin/1" : data.link
                  }
                >
                  <div
                    onClick={() => handleShowList(index)}
                    className={`${styles.serviceBtn} ${
                      ((index === 0 &&
                        (location.pathname.includes("/admin/1") ||
                          location.pathname.includes("/admin/2") ||
                          location.pathname.includes("/admin/3") ||
                          location.pathname.includes("/admin/4") ||
                          location.pathname.includes("/admin/5") ||
                          location.pathname.includes("/admin/6"))) ||
                        (index !== 0 &&
                          data.link.includes(location.pathname))) &&
                      styles.active
                    }`}
                  >
                    {data.icon}
                    <div className={styles.serviceBtnContent}>{data.name}</div>
                  </div>
                </Link>

                {menu?.length > 0 && isToggleOn && index === 0 && (
                  <div className={styles.serviceBtnListDetail}>
                    {menu?.map((menu, index) => (
                      <Link to={`/admin/${menu.id}`} key={index}>
                        <div
                          className={`${styles.serviceList} ${
                            menu.id === parseInt(params.id) && styles.active
                          }`}
                          onClick={() => setMenuAdminIndex(0)}
                        >
                          <div className={styles.serviceBtnContent}>
                            {menu.name}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}

          {auth?.roles?.find((item) => item === "workspace.user") &&
            !auth?.roles?.find((item) => item === "workspace.admin") && (
              <>
                {menu?.map(
                  (menu, index) =>
                    auth?.services?.find((item) => item.id === menu.id) && (
                      <Link to={`/dashboard/business/${menu.id}`} key={index}>
                        <div
                          className={`${styles.serviceBtn} ${
                            menu.id === parseInt(params.id) && styles.active
                          }`}
                        >
                          <div className={styles.serviceBtnContent}>
                            {menu.name}
                          </div>
                        </div>
                      </Link>
                    )
                )}

                <hr
                  style={{ border: "none", borderBottom: "1px solid #cccccc" }}
                />

                {auth?.workspace_permissions?.length > 0 && (
                  <Link to={"/admin/workspace"}>
                    <div
                      onClick={() => setMenuAdminIndex(1)}
                      className={`${styles.serviceBtn} ${
                        location.pathname.includes("admin/workspace") &&
                        styles.active
                      }`}
                    >
                      <MdDashboard className={styles.serviceIcon} />
                      <div className={styles.serviceBtnContent}>Workspace</div>
                    </div>
                  </Link>
                )}
                {auth?.roles?.find((item) => item === "workspace.user") && (
                  <Link to={"/admin/signed"}>
                    <div
                      onClick={() => setMenuAdminIndex(1)}
                      className={`${styles.serviceBtn} ${
                        location.pathname.includes("admin/signed") &&
                        styles.active
                      }`}
                    >
                      <MdOutlineMenuBook className={styles.serviceIcon} />
                      <div className={styles.serviceBtnContent}>報名列表</div>
                    </div>
                  </Link>
                )}
              </>
            )}

          {auth?.roles?.find((item) => item === "workspace.tdp") &&
            menuTDP.map((data, index) => (
              <div
                key={index}
                className={styles.list}
                onClick={() => {
                  if (navigateEditMode) {
                    dispatch(editModeToggleHandler(true));
                  }
                }}
              >
                {!navigateEditMode && (
                  <Link to={data.link}>
                    <div
                      onClick={(e) => {
                        handleShowList(index);
                        handleClickEnterpriseLink(e, data.link);
                      }}
                      className={`${styles.serviceBtn} ${
                        location.pathname.includes(data.group) && styles.active
                      }`}
                    >
                      {data.icon}
                      <div className={styles.serviceBtnContent}>
                        {data.name}
                      </div>
                    </div>
                  </Link>
                )}
                {navigateEditMode && (
                  <div
                    onClick={() => {
                      handleShowList(index);
                    }}
                    className={`${styles.serviceBtn} ${
                      location.pathname.includes(data.group) && styles.active
                    }`}
                  >
                    {/* <img className={styles.serviceIcon} src={data.icon} alt='' /> */}
                    {data.icon}
                    <div className={styles.serviceBtnContent}> {data.name}</div>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className={styles.naviBottom}>
          <div className={styles.adminBlock}>
            <div className={styles.adminFlexLeft}>
              <div className={styles.adminTitle}>
                {auth?.roles.find((item) => item === "workspace.admin") &&
                  "Workspace Admin"}

                {auth?.roles.find((item) => item === "workspace.user") &&
                  !auth?.roles.find((item) => item === "workspace.admin") &&
                  "Workspace User"}

                {auth?.roles.find((item) => item === "workspace.tdp") &&
                  "Workspace TDP"}
              </div>
              <div className={styles.adminName}>
                {auth?.roles.find((item) => item === "workspace.admin")
                  ? "Admin"
                  : auth?.roles.find((item) => item === "workspace.tdp")
                  ? "TDP"
                  : auth?.name}
              </div>
            </div>
            <div className={styles.adminFlexRight}>
              <div className={styles.adminLastName}>
                {auth?.roles.find((item) => item === "workspace.admin")
                  ? "A"
                  : auth?.roles.find((item) => item === "workspace.tdp")
                  ? "T"
                  : auth?.name?.substr(0, 1)}
              </div>
            </div>
          </div>
          <Button type="primary" title="登出" onClick={logOut} fullWidth />
        </div>
      </div>
      {/* <Alert
        mainTitle='跳過正在編輯項目？'
        subTitle='系統可能不會儲存你所做的變更'
        rightButtonText='跳過'
        leftButtonText='繼續編輯'
        isOpen={isAlertShow}
        onClose={() => setIsAlertShow(false)}
        // onConfirm={() => {
        //   setIsEditMode(false);
        //   setIsSnackBarOpen(false);
        //   dispatch(editModeHandler(false));
        // }}
      /> */}
    </>
  );
};

export default LeftNavigation;

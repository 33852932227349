import styles from './style/layout.module.scss';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getManagerPermissionsV2, selectPermissionSettings, postUserManagementSettingV2, removeSuccess } from '../../store/slice/permissionSettings';

import Button from '../Utilities/Button';
import Drawer from '../Utilities/Drawer';
import Checkbox from '../Utilities/Checkbox';

const WorkspacePermission = ({ user, setUser, setIsToastShow, setUserPermissionSuccess }) => {
  const dispatch = useDispatch();
  const { manager_permissions, success } = useSelector(selectPermissionSettings);
  const [userPermissions, setUserPermissions] = useState([]);
  const [permissionCount, setPermissionCount] = useState(0);

  // get user permission 資料  放入userPermissions
  useEffect(() => {
    if (user) dispatch(getManagerPermissionsV2(user.id));
  }, [dispatch, user]);

  // 放入userPermissions
  useEffect(() => {
    if (manager_permissions?.rows?.length > 0) {
      setUserPermissions(manager_permissions.rows);
    }
  }, [manager_permissions]);

  // set count
  useEffect(() => {
    let count = 0;
    userPermissions?.forEach((item) => {
      if (!item.name.includes('view') && item.has_permission) {
        count++;
      }
    });
    setPermissionCount(count);
  }, [userPermissions]);

  function handleSetPermission(item, index) {
    let arr = JSON.parse(JSON.stringify(userPermissions));
    arr[index].has_permission = !item.has_permission;

    // 勾選'非檢視'的管理權限時，若檢視權限未啟用，會連動被啟動。
    if (!item.name.includes('view') && !item.has_permission && !userPermissions?.find((item) => item.name.includes('view')).has_permission) {
      let idx = arr.findIndex((item) => item.name.includes('view'));
      arr[idx].has_permission = true;
    }

    setUserPermissions(arr);
  }

  function handleCheckAll() {
    let arr = JSON.parse(JSON.stringify(userPermissions));
    arr.forEach((item) => (item.has_permission = true));
    setUserPermissions(arr);
  }

  function handleClearAll() {
    let arr = JSON.parse(JSON.stringify(userPermissions));
    arr.forEach((item) => {
      if (item.can_enable) item.has_permission = false;
    });
    setUserPermissions(arr);
  }

  function submitPermissions() {
    let arr = JSON.parse(JSON.stringify(userPermissions));
    let data = [];
    arr.forEach((item) => {
      data.push({
        workspace_user: user.id,
        name: item.name,
        has_permission: item.has_permission ? true : false,
      });
    });
    dispatch(postUserManagementSettingV2(data));
  }

  // 成功儲存user資料。
  useEffect(() => {
    if (success) {
      dispatch(removeSuccess());
      setUserPermissions([]);
      setPermissionCount(0);
      setIsToastShow(true);
      setUserPermissionSuccess({ isSuccess: true, name: user.name });
      setUser('');
      setTimeout(() => {
        setIsToastShow(false);
      }, 1500);
      setTimeout(() => {
        setUserPermissionSuccess({ isSuccess: false, name: '' });
      }, 2000);
    }
  }, [dispatch, success, setUser, setIsToastShow, setUserPermissionSuccess, user.name]);

  return (
    <Drawer isOpen={user} size={'md'}>
      <div className={styles.headerContainer}>
        <div className={styles.permissionTitle}>設定 User 權限</div>
        <div className={styles.headerRight}>{user?.user_name}</div>
      </div>

      <div className={styles.headerTop}>賦予權限給該使用者該 Workspace 內進行管理。</div>

      <div className={styles.listTopic}>Workspace 管理</div>
      <div className={styles.managementlist}>
        {userPermissions?.length > 0 &&
          userPermissions?.map((item, index) => (
            <>
              <div className={styles.checkboxOuter} key={`checklist-${index}`}>
                <div className={styles.planTitle}>{item.name_local}</div>
                <Checkbox
                  value={item?.has_permission}
                  isDisable={(item.name.includes('view') && item.has_permission && permissionCount > 0) || !item.can_enable}
                  color={'#5856D6'}
                  onClick={() => handleSetPermission(item, index)}
                />
              </div>
            </>
          ))}
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.btnFlex}>
          <div className={styles.dflex}>
            <Button type='tertiary' title={'全選'} onClick={handleCheckAll} />
            <Button type='quaternary' title={'清除全部'} onClick={handleClearAll} />
          </div>
          <div>
            <div className={styles.dflex}>
              <div className={styles.mright}>
                <Button
                  type='tertiary'
                  title={'取消'}
                  onClick={() => {
                    setUser('');
                    setUserPermissions([]);
                    setPermissionCount(0);
                  }}
                />
              </div>
              <Button type='primary' title={'儲存設定'} onClick={submitPermissions} />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default WorkspacePermission;

import styles from './style/layout.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import InnerLayout from '../Layout/InnerLayout';
import { BsShieldFillCheck, BsCheck, BsX } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { AiFillSetting } from 'react-icons/ai';
import { BiChevronLeft } from 'react-icons/bi';
import { MdAccountCircle, MdDeleteOutline, MdEdit } from 'react-icons/md';
import Button from '../Utilities/Button';
import EmptyBox from '../Utilities/EmptyBox';
import Pagination from '../Utilities/PaginationNew';
import Tag from '../Utilities/Tag';
import Alert from '../Utilities/Alert';
import InputSearch from '../Utilities/InputSearch';
import WorkspaceAssignModal from './WorkspaceAssignModal';
import WorkspaceUserModal from './WorkspaceUserModal';
import WorkspacePermission from './WorkspacePermission';
import WorkspaceManager from './WorkspaceManager';
import { useSelector, useDispatch } from 'react-redux';

import { selectWorkspaceStates, assignModalOpen } from '../../store/slice/workspaceStates';

import {
  selectWorkspaceListsSlice,
  getWorkspaceUserListV2,
  deleteWorkspaceUserV2,
  updateWorkspaceName,
  removeSuccess,
} from '../../store/slice/workspaceList';

import moment from 'moment';
import LoadingTable from '../Utilities/LoadingTable/index';
import { selectPermissionSettings } from '../../store/slice/permissionSettings';

const WorkspaceDetail = ({
  isOpen,
  setFoward,
  workspaceId,
  foward,
  setIsUserDelete,
  setIsToastShow,
  delIndex,
  setDelIndex,
  setUserPermissionSuccess,
}) => {
  // DISPATCH
  const dispatch = useDispatch();
  // INITIAL STATES
  const wsStates = useSelector(selectWorkspaceStates);
  const { auth, permissions } = useSelector(selectPermissionSettings);

  const pageLimit = 10;

  //STATES
  const assignModal = wsStates.assignModal;
  const [inputOpen, setInputOpen] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [deleteSnackBar, setDeleteSnackBar] = useState(false);
  const [userSettings, setUserSettings] = useState(false);
  const [settingIndex, setSettingIndex] = useState();
  const [renameError, setRenameError] = useState({});
  const [userAssigned, setUserAssigned] = useState({});

  const [permissionSettings, setPermissionSettings] = useState({});
  const [workspaceName, setworkspaceName] = useState();
  const [status, setStatus] = useState(false);

  const [init, setInit] = useState(true); //判負責人的hover是否為初次
  const [isRepresentHover, setIsRepresentHover] = useState(false); //判負責人的hover是否為初次

  const [user, setUser] = useState('');

  const handleNewWorkspace = () => {
    setCreateUserModal(true);
  };

  const handleAssign = () => {
    setStatus(false);
    dispatch(assignModalOpen());
    setUserAssigned({ isUser: false, userId: false });
    setworkspaceName(workspace?.getWorkspaceUserListData?.center_workspace_name);
  };

  const handleUserAssign = (userId) => {
    setStatus(true);
    dispatch(assignModalOpen());
    setUserAssigned({ isUser: true, userId: userId });
    setworkspaceName(foward?.fowardName);
  };

  const handleSetPermission = () => {
    setPermissionSettings({
      modalOpen: true,
      isUserPermission: false,
      workspace_name: foward?.fowardName,
      manage_service: foward?.services_item?.name,
    });
    setSettingIndex(null);
  };

  const handleSetUserPermission = (data) => {
    setPermissionSettings({
      modalOpen: !permissionSettings.modalOpen,
      isUserPermission: true,
      workspace_name: foward?.fowardName,
      manage_service: foward?.services_item?.name,
      user_id: data.id,
    });
    setUserSettings(false);
  };

  const handleInputCheck = () => {
    if (workspaceName.length > 30) {
      setRenameError({ nameOverLength: true, noLength: false });
    } else if (workspaceName.length === 0) {
      setRenameError({ nameOverLength: false, noLength: true });
    } else {
      dispatch(
        updateWorkspaceName({
          center_workspace_id: workspaceId,
          name: workspaceName,
        })
      );
      setFoward({ ...foward, fowardName: workspaceName });
      setInputOpen(false);
      setRenameError({});
    }
  };

  const handleInputCancel = () => {
    setInputOpen(false);
    setworkspaceName(foward?.fowardName);
    setRenameError({});
  };
  const handleToastDelete = (data) => {
    setDeleteSnackBar(true);
    setDelIndex({ user_id: data.id, user_name: data.user_name });
  };

  const handleUserSettingBtn = (user_id) => {
    setSettingIndex(user_id);
    if (userSettings === false) {
      setUserSettings(true);
    } else {
      setUserSettings(false);
    }
  };

  const handleDeleteUser = () => {
    dispatch(
      deleteWorkspaceUserV2({
        workspace_user: delIndex.user_id,
      })
    );
    if (workspace?.getWorkspaceUserListData?.total % pageLimit === 1) setPage(page - 1);
    setDeleteSnackBar(false);
    setIsToastShow(true);
    setIsUserDelete(true);
    setTimeout(() => {
      setIsToastShow(false);
      setIsUserDelete(false);
    }, 2000);
  };

  // NEW STATES
  const workspace = useSelector(selectWorkspaceListsSlice);
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchSearchValue, setFetchSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (userSettings) {
          setUserSettings(!userSettings);
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, userSettings]);

  // NEW EFFECTS
  useEffect(() => {
    if (workspaceId || workspace?.success)
      dispatch(
        getWorkspaceUserListV2({
          search: fetchSearchValue,
          workspace: workspaceId,
          page: fetchSearchValue ? 1 : page + 1,
          limit: pageLimit,
        })
      );
    if (workspace?.success) dispatch(removeSuccess());
    if (fetchSearchValue) setPage(0);
  }, [dispatch, workspaceId, fetchSearchValue, page, workspace?.success]);

  useEffect(() => {
    setSearchTerm('');
    setFetchSearchValue('');
    setworkspaceName(foward?.fowardName);
  }, [foward]);

  const restart = () => {
    setFetchSearchValue('');
    let parameters = {
      limit: pageLimit,
      workspace: workspaceId,
      page: page + 1,
      search: '',
    };
    dispatch(getWorkspaceUserListV2(parameters));
  };

  return (
    <>
      <WorkspaceAssignModal
        isOpen={assignModal}
        workspaceId={workspaceId}
        userAssigned={userAssigned}
        workspaceName={workspaceName}
        status={status}
      />

      <WorkspacePermission
        foward={foward}
        setFoward={setFoward}
        permissionSettings={permissionSettings}
        setPermissionSettings={setPermissionSettings}
        isUser={permissionSettings.isUserPermission}
        workspaceId={workspaceId}
        settingIndex={settingIndex}
      />

      <WorkspaceManager user={user} setUser={setUser} setIsToastShow={setIsToastShow} setUserPermissionSuccess={setUserPermissionSuccess} />

      <Alert
        isOpen={deleteSnackBar}
        onClose={() => setDeleteSnackBar(false)}
        onConfirm={handleDeleteUser}
        mainTitle={`刪除使用者「${delIndex.user_name}」?`}
        leftButtonText={'跳過'}
        rightButtonText={'刪除'}
        isWarning={true}
      />

      <InnerLayout>
        <div className={isOpen ? styles.workspaceListOpen : styles.workspaceListClose}>
          <div className={styles.ws}>
            {workspace?.tableLoading ? (
              <Skeleton width={'100%'} height={53} />
            ) : (
              <div className={styles.titleContainer}>
                <div className={styles.dflex}>
                  <div className={styles.dashboardTitle} style={{ display: inputOpen ? 'none' : 'block' }}>
                    {foward?.fowardName}
                  </div>
                  {!inputOpen && auth?.roles?.find((item) => item === 'workspace.admin') && (
                    <Button type={'pure'} onClick={() => setInputOpen(true)} icon={<MdEdit />} color={'#333333'} />
                  )}
                  <div className={inputOpen ? styles.inputContainer : styles.inputClose}>
                    <input
                      id='workspaceName'
                      className={renameError.noLength || renameError.nameOverLength ? styles.editWorkspaceError : styles.editWorkspace}
                      type='text'
                      value={workspaceName ? workspaceName : ''}
                      onChange={(e) => {
                        setworkspaceName(e.target.value);
                        setRenameError({});
                      }}
                    />
                    <div className={styles.iconContainer}>
                      <div className={styles.iconSquare} onClick={handleInputCancel}>
                        <BsX className={styles.iconWidth} />
                      </div>
                      <div className={styles.iconSquare} onClick={handleInputCheck}>
                        <BsCheck className={styles.iconWidth} />
                      </div>
                    </div>

                    {renameError.noLength && <p className={styles.errorMsg}>workspace名稱不可為空</p>}
                    {renameError.nameOverLength && <p className={styles.errorMsg}>workspace名稱不可超過30字</p>}
                  </div>
                </div>

                <div
                  className={styles.headerRightContainer}
                  style={{
                    minWidth: auth?.roles.find(
                      (item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)
                    )
                      ? '565px'
                      : '0',
                  }}
                >
                  {auth?.roles?.find((item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)) && (
                    <>
                      <Button
                        icon={<BsShieldFillCheck />}
                        type='quaternary'
                        title='指派企業最高管理權限'
                        color={'#333333'}
                        onClick={handleAssign}
                        disabled={inputOpen}
                      />
                      <Button
                        icon={<AiFillSetting />}
                        type='quaternary'
                        title='設定服務最高管理權限'
                        color={'#333333'}
                        onClick={handleSetPermission}
                        disabled={inputOpen}
                      />
                    </>
                  )}

                  <Button
                    type='tertiary'
                    title={'返回workspace'}
                    onClick={() => {
                      setFoward(false);
                      setInputOpen(false);
                      setworkspaceName(foward?.fowardName);
                    }}
                    icon={<BiChevronLeft />}
                  />
                </div>
              </div>
            )}

            <div className={styles.dashboardListContainer}>
              <div className={styles.dashboardListTitle}>User 列表</div>
              <div>
                <Tag title={`${foward?.services_item?.alias}`} isDisplayOnly={true} />
              </div>
            </div>

            <div className={styles.headContainer}>
              <div className={styles.searchBar}>
                <InputSearch
                  disabled={workspace?.loaing || (fetchSearchValue === '' && workspace?.getWorkspaceUserListData?.rows?.length === 0)}
                  placeholder='搜尋使用者姓名或電子郵件'
                  value={searchTerm ? searchTerm : ''}
                  onKeyPress={(e) => (e.code === 'Enter' || e.charCode === 13) && !workspace?.loaing && setFetchSearchValue(searchTerm)}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  clickSearchIcon={() => {
                    if (!workspace?.loaing) {
                      setFetchSearchValue(searchTerm);
                      setPage(1);
                    }
                  }}
                  onClear={() => {
                    restart();
                    setSearchTerm('');
                  }}
                  width={'230px'}
                />
              </div>
              {(permissions?.find((item) => item === 'manager.user_management') ||
                auth?.roles?.find((item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`))) && (
                <Button type={'tertiary'} title={'新增使用者'} onClick={() => setCreateUserModal(true)} />
              )}

              <WorkspaceUserModal isOpen={createUserModal} setCreateUserModal={setCreateUserModal} workspaceId={workspaceId} />
            </div>

            <div className={styles.tableContainer}>
              <table>
                <thead>
                  <tr>
                    <th>使用者姓名</th>
                    <th className={styles.mail}>電子郵件</th>
                    <th className={styles.joint}>加入時間</th>
                    <th className={styles.verify}>認證狀態</th>
                    <th className={styles.btnGroup}></th>
                    <th className={styles.delete}></th>
                  </tr>
                </thead>

                {workspace?.tableLoading ? (
                  <LoadingTable row={10} column={6} />
                ) : (
                  <>
                    {!workspace?.tableLoading && workspace?.getWorkspaceUserListData?.rows?.length > 0 ? (
                      <tbody>
                        {workspace?.getWorkspaceUserListData?.rows?.map((data) => (
                          <React.Fragment key={data.id}>
                            <tr>
                              <td className={styles.tableTd}>{data.user_name}</td>
                              <td className={styles.tableTd}>{data.user_email}</td>
                              <td className={styles.tableTd}>{moment(data.created_at).format('YYYY-MM-DD')}</td>
                              <td className={styles.tableTd}>{data.is_verify ? '已認證' : '未認證'}</td>
                              <td className={styles.tableTd}>
                                <div className={styles.btnContainer}>
                                  {(permissions?.find(
                                    (item) => item === 'manager.service_permission_management' || item === 'manager.workspace_management'
                                  ) ||
                                    auth?.roles?.find(
                                      (item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_sys_alias}`)
                                    )) && (
                                    <div className={styles.btnflex}>
                                      <Button type={'tertiary'} title={'設定權限'} onClick={() => handleUserSettingBtn(data.id)} />
                                      {settingIndex === data.id && userSettings && (
                                        <div ref={wrapperRef} className={userSettings ? styles.ModalBtn : styles.modal}>
                                          {(permissions?.find((item) => item === 'manager.workspace_management') ||
                                            auth?.roles?.find(
                                              (item) =>
                                                item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)
                                            )) && (
                                            <div>
                                              <Button
                                                type={'tertiary'}
                                                fullWidth={true}
                                                title={'設定服務權限'}
                                                onClick={() => handleSetUserPermission(data)}
                                              />
                                            </div>
                                          )}

                                          {(permissions?.find((item) => item === 'manager.service_permission_management') ||
                                            permissions?.find((item) => item === 'manager.workspace_management') ||
                                            auth?.roles?.find(
                                              (item) =>
                                                item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)
                                            )) && (
                                            <div className={styles.btnMargin}>
                                              <Button
                                                type={'tertiary'}
                                                fullWidth={true}
                                                title={'設定User權限'}
                                                onClick={() => {
                                                  setUser(data);
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(permissions?.find((item) => item === 'manager.assign_enterprise_management') ||
                                    auth?.roles?.find(
                                      (item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)
                                    )) && (
                                    <div className={styles.btnflex}>
                                      <Button type={'tertiary'} title={'指派企業'} onClick={() => handleUserAssign(data.user_id)} />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className={styles.tableTd}>
                                {(permissions?.find((item) => item === 'manager.user_management') ||
                                  auth?.roles?.find(
                                    (item) => item === 'workspace.admin' || item.includes('manager' && `${foward?.services_item?.sys_alias}`)
                                  )) && (
                                  <>
                                    {data.is_representative ? (
                                      <div className={styles.representIconOuter}>
                                        <div
                                          className={styles.btnOuter}
                                          onMouseEnter={() => setIsRepresentHover(true)}
                                          onMouseLeave={() => setIsRepresentHover(false)}
                                        >
                                          <Button type={'pure'} icon={<MdAccountCircle />} />
                                        </div>
                                        {(init || (!init && isRepresentHover)) && (
                                          <div className={styles.tips}>
                                            <span>使用者為負責人，無法刪除。請先更換負責人再刪除該使用者。</span>
                                            {init && <Button type='pure' icon={<BsX />} color={'#FFFFFF'} onClick={() => setInit(false)} />}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className={styles.representIconOuter}>
                                        <Button type={'pure'} icon={<MdDeleteOutline />} onClick={() => handleToastDelete(data)} />
                                      </div>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        {!workspace?.tableLoading && workspace?.getWorkspaceUserListData?.rows?.length === 0 && (
                          <tr>
                            <td colSpan='7'>
                              <EmptyBox
                                caption={fetchSearchValue ? '搜尋無結果，請更換搜尋關鍵字' : '尚未新增使用者'}
                                title={fetchSearchValue ? '' : '新增使用者'}
                                onClick={handleNewWorkspace}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </>
                )}
              </table>
              <Pagination
                pageCount={page + 1}
                onPageChange={(e) => {
                  setPage(e.selected);
                }}
                totalData={workspace?.getWorkspaceUserListData?.total}
                limit={pageLimit}
                totalPages={Math.ceil(workspace?.getWorkspaceUserListData?.total / pageLimit)}
              />
            </div>
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default WorkspaceDetail;

import axios from "axios";
import { logOut } from "../utils";

export async function sendApiRequest(method, url, payload, isImage) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_URL ?? "") + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else if (token && isImage) {
    settings.headers = {
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: "Bearer" + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    console.error("Error when calling API: ", err?.response);
    return err?.response;
  }
}

export async function sendApiClientRequest(method, url, payload, isImage, responseType) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_CLIENT_API_URL ?? "") + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else if (token && isImage) {
    settings.headers = {
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: "Bearer" + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  } 
  if (responseType) {
    settings.responseType = responseType
  }

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    if (err?.response?.status === 401) {
      logOut();
    }
    return err?.response;
  }
}


export async function sendApiClientRequestForArticle(method, url, payload) {

  const getCookie = document.cookie;
  const getToken = getCookie.split("; token=");
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_CLIENT_API_URL ?? "") + url,
  };

  const formData = new FormData();

  if (payload) {
  
    Object.keys(payload).forEach((key) => {
      if (key !== 'contents') {
        if (payload[key] instanceof File) {
          formData.append(key, payload[key]);
        } else {
          formData.append(key, payload[key]);
        }
      }
    });


    if (payload.contents && Array.isArray(payload.contents)) {
      payload.contents.forEach((content, index) => {
        Object.keys(content).forEach((contentKey) => {
          const contentValue = content[contentKey];

          if (contentValue instanceof File) {
            formData.append(`contents[${index}][${contentKey}]`, contentValue);
          }
          
          else if (Array.isArray(contentValue)) {
            contentValue.forEach((subContent, subIndex) => {
              if (subContent instanceof File) {
                formData.append(`contents[${index}][${contentKey}][${subIndex}]`, subContent);
              } else {
                formData.append(`contents[${index}][${contentKey}][${subIndex}]`, subContent);
              }
            });
          }
          
          else {
            formData.append(`contents[${index}][${contentKey}]`, contentValue);
          }
        });
      });
    }
  }

  if (token) {
    settings.headers = {
      "Authorization": "Bearer " + token,
    };
  }


  settings.headers = {
    ...settings.headers,
    "Content-Type": "multipart/form-data",
  };


  settings.data = formData;

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    if (err?.response?.status === 401) {
      logOut();
    }
    return err?.response;
  }
}


export async function sendEditApiClientRequestForArticle(method, url, payload) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_CLIENT_API_URL ?? "") + url,
  };

  const formData = new FormData();
  const addedFileIds = new Set();

  const appendFileData = (key, fileData) => {
    if (!addedFileIds.has(fileData.file_id)) {
      formData.append(`${key}[file_id]`, fileData.file_id);
      formData.append(`${key}[file_name]`, fileData.file_name);
      formData.append(`${key}[file_path]`, fileData.file_path);
      formData.append(`${key}[url]`, fileData.url);
      addedFileIds.add(fileData.file_id);
    }
  };

  if (payload) {
    Object.keys(payload).forEach((key) => {
      if (payload[key]?.file_id) {
        appendFileData(key, payload[key]);
      } else {
        if (key === 'contents' && Array.isArray(payload[key])) {
          payload[key].forEach((content, contentIndex) => {
            if (Array.isArray(content.images)) {
              content.images.forEach((image, imageIndex) => {
                if (image && image.file_id && image.file_name && image.file_path && image.url) {
                  appendFileData(`contents[${contentIndex}][images][${imageIndex}]`, image); // 添加图片
                }
              });
            }
          });
        } else {

          if (typeof payload[key] === 'object' && payload[key] !== null && payload[key]?.url) {
            payload[key] = payload[key].url;
          }

          if (payload[key] instanceof File) {
            formData.append(key, payload[key]);
          } else {
            formData.append(key, payload[key]);
          }
        }
      }
    });

    if (payload.contents && Array.isArray(payload.contents)) {
      payload.contents.forEach((content, index) => {
        Object.keys(content).forEach((contentKey) => {
          if (content[contentKey] instanceof File) {
            formData.append(`contents[${index}][${contentKey}]`, content[contentKey]);
          } else if (Array.isArray(content[contentKey])) {
            content[contentKey].forEach((subContent, subIndex) => {
              if (subContent?.file_id && subContent?.file_name && subContent?.file_path && subContent?.url) {
                appendFileData(`contents[${index}][${contentKey}][${subIndex}]`, subContent);
              } else {
                formData.append(`contents[${index}][${contentKey}][${subIndex}]`, subContent);
              }
            });
          } else {
            formData.append(`contents[${index}][${contentKey}]`, content[contentKey]);
          }
        });
      });
    }
  }

  if (token) {
    settings.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  }

  settings.data = formData;

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    if (err?.response?.status === 401) {
      logOut();
    }
    return err?.response;
  }
}



export async function sendApiClientRequestExcel(method, url, payload, isImage, responseType) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_CLIENT_API_URL ?? "") + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else if (token && isImage) {
    settings.headers = {
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: "Bearer" + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  } 
  if (responseType) {
    settings.responseType = responseType
  }

  try {
    const res = await axios(settings);
    if (res && res.data && res.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const fileUrl = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '工作坊名單.xlsx';
      link.click();
      URL.revokeObjectURL(fileUrl);
    }
    return res;
  } catch (err) {
    if (err?.response?.status === 401) {
      logOut();
    }
    return err?.response;
  }
}
import styles from "./style/layout.module.scss";
import InnerLayout from "../Layout/InnerLayout";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiExport } from "react-icons/bi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

import Pagination from "../Utilities/PaginationNew";
import InputSearch from "../Utilities/InputSearch";

import {
  selectWorkspaceListsSlice,
  getSinged,
  getSingedExcel,
} from "../../store/slice/workspaceList";
import LoadingTable from "../Utilities/LoadingTable";

const Signed = () => {
  // DISPATCH
  const dispatch = useDispatch();

  // INITIAL ARRAY
  const { loading, singedList } = useSelector(selectWorkspaceListsSlice);

  const [sortByValue, setSortByValue] = useState("created_at");

  const [timeSort, setTimeSort] = useState(true);

  const [enterpriseSort, setEnterpriseSort] = useState(false);

  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [fetchSearchValue, setFetchSearchValue] = useState("");

  const limit = 10;

  // workspace List Data
  useEffect(() => {
    if (fetchSearchValue !== "") {
      let parameters = {
        limit: limit,
        page: page,
        search: fetchSearchValue,
        sort_by: "created_at",
        sort_method: "desc",
      };
      dispatch(getSinged(parameters));
    } else {
      let parameters = {
        limit: limit,
        page: page,
        sort_by: "created_at",
        sort_method: "desc",
      };
      dispatch(getSinged(parameters));
      setTimeSort(true);
      setEnterpriseSort(false);
      // if (!enterpriseSort && !timeSort) {
      //   let parameters = {
      //     limit: limit,
      //     page: page,
      //   };
      //   dispatch(getSinged(parameters));
      // }
      // if (timeSort && !enterpriseSort) {
      //   let parameters = {
      //     limit: limit,
      //     page: page,
      //     sort_by: "created_at",
      //     sort_method: "desc",
      //   };
      //   dispatch(getSinged(parameters));
      // }
      // if (enterpriseSort && !timeSort) {
      //   let parameters = {
      //     limit: limit,
      //     page: page,
      //     sort_by: "enterprise_title",
      //     sort_method: "desc",
      //   };
      //   dispatch(getSinged(parameters));
      // }
    }
  }, [dispatch, fetchSearchValue, page]);

  const restart = () => {
    setFetchSearchValue("");
    setPage(1);
  };

  return (
    <>
      <InnerLayout>
        <div className={styles.dashboard}>
          <div className={styles.dashboardWorkspace}>
            <div className={styles.dashboardTitle}>報名表單</div>
            <div className={styles.dashboardListContainer}>
              <div className={styles.singedListTitle}>表單列表</div>
              <div className={styles.singedBtnContainer}>
                <InputSearch
                  placeholder="搜尋企業名稱"
                  value={searchTerm}
                  width={"150px"}
                  height={"25px"}
                  onKeyPress={(e) =>
                    (e.code === "Enter" || e.charCode === 13) &&
                    setFetchSearchValue(searchTerm)
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                  clickSearchIcon={() => {
                    setFetchSearchValue(searchTerm);
                    setPage(1);
                  }}
                  onClear={() => {
                    restart();
                    setSearchTerm("");
                  }}
                />
                <div
                  className={styles.btn}
                  onClick={() => {
                    setFetchSearchValue(searchTerm);
                    setPage(1);
                  }}
                >
                  搜尋
                </div>
                <div
                  className={styles.btn}
                  onClick={() => {
                    dispatch(getSingedExcel());
                  }}
                >
                  <BiExport fontSize={20} />
                  匯出
                </div>
              </div>
            </div>
            {singedList?.data?.rows?.length < 0 && (
              <div style={{ paddingLeft: "72px", paddingRight: "56px" }}>
                <Pagination
                  onPageChange={(e) => {
                    setPage(e.selected);
                  }}
                  pageCount={page}
                  totalData={singedList?.data?.total}
                  totalPages={Math.ceil(singedList?.data?.total / limit)}
                  limit={limit}
                />
              </div>
            )}
            <div className={styles.tableContainer}>
              {loading ? (
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "12%", cursor: "pointer" }}>
                        報名時間
                        <span className={styles.iconSpan}>
                          <TiArrowSortedDown color="#000000" fontSize={20} />
                        </span>
                      </th>
                      <th style={{ width: "12%" }}>姓名</th>
                      <th style={{ width: "12%", cursor: "pointer" }}>
                        企業名稱
                        <span className={styles.iconSpan}>
                          <TiArrowSortedDown color="#000000" fontSize={20} />
                        </span>
                      </th>
                      <th style={{ width: "12%" }}>職位</th>
                      <th style={{ width: "10%" }}>國碼</th>
                      <th style={{ width: "12%" }}>手機</th>
                      <th style={{ width: "30%" }}>電子郵件</th>
                    </tr>
                  </thead>
                  <LoadingTable row={10} column={7} />
                </table>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{ width: "12%", cursor: "pointer" }}
                        onClick={() => {
                          if (!timeSort) {
                            let parameters = {
                              limit: limit,
                              page: page,
                              search: fetchSearchValue,
                              sort_by: "created_at",
                              sort_method: "desc",
                            };
                            setSortByValue("created_at");
                            dispatch(getSinged(parameters));
                          }
                          if (timeSort) {
                            let parameters = {
                              limit: limit,
                              page: page,
                              search: fetchSearchValue,
                              sort_by: "created_at",
                              sort_method: "asc",
                            };
                            setSortByValue("created_at");
                            dispatch(getSinged(parameters));
                          }
                          setTimeSort(!timeSort);
                          setEnterpriseSort(false);
                        }}
                      >
                        報名時間
                        <span className={styles.iconSpan}>
                          {timeSort ? (
                            <TiArrowSortedUp
                              color={sortByValue === "created_at" ? "#5669d6" : "#000000"}
                              fontSize={20}
                            />
                          ) : (
                            <TiArrowSortedDown
                              color={sortByValue === "created_at" ? "#5669d6" : "#000000"}
                              fontSize={20}
                            />
                          )}
                        </span>
                      </th>
                      <th style={{ width: "12%" }}>姓名</th>
                      <th
                        style={{ width: "12%", cursor: "pointer" }}
                        onClick={() => {
                          if (!enterpriseSort) {
                            let parameters = {
                              limit: limit,
                              page: page,
                              search: fetchSearchValue,
                              sort_by: "enterprise_title",
                              sort_method: "desc",
                            };
                            setSortByValue("enterprise_title");
                            dispatch(getSinged(parameters));
                          }
                          if (enterpriseSort) {
                            let parameters = {
                              limit: limit,
                              page: page,
                              search: fetchSearchValue,
                              sort_by: "enterprise_title",
                              sort_method: "asc",
                            };
                            setSortByValue("enterprise_title");
                            dispatch(getSinged(parameters));
                          }
                          setEnterpriseSort(!enterpriseSort);
                          setTimeSort(false);
                        }}
                      >
                        企業名稱
                        <span className={styles.iconSpan}>
                          {enterpriseSort ? (
                            <TiArrowSortedUp
                              color={sortByValue === "enterprise_title" ? "#5669d6" : "#000000"}
                              fontSize={20}
                            />
                          ) : (
                            <TiArrowSortedDown
                              color={sortByValue === "enterprise_title" ? "#5669d6" : "#000000"}
                              fontSize={20}
                            />
                          )}
                        </span>
                      </th>
                      <th style={{ width: "12%" }}>職位</th>
                      <th style={{ width: "10%" }}>國碼</th>
                      <th style={{ width: "12%" }}>手機</th>
                      <th style={{ width: "30%" }}>電子郵件</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singedList?.data?.rows?.length > 0 ? (
                      singedList?.data?.rows?.map((item, index) => (
                        <tr key={index}>
                          <td
                            className={styles.tableTd}
                            style={{ width: "12%" }}
                          >
                            {item.created_at} {/* 渲染報名時間 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "12%" }}
                          >
                            {item.name} {/* 渲染姓名 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "12%" }}
                          >
                            {item.enterprise_title} {/* 渲染企業名稱 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "12%" }}
                          >
                            {item.job_title} {/* 渲染職位 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "10%" }}
                          >
                            {item.phone_country} {/* 渲染國碼 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "12%" }}
                          >
                            {item.phone} {/* 渲染手機 */}
                          </td>
                          <td
                            className={styles.tableTd}
                            style={{ width: "30%" }}
                          >
                            {item.email} {/* 渲染電子郵件 */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr style={{ border: "1px solid red" }}>
                        <td colSpan="7">
                          <div className={styles.noResult}>
                            <h4>搜尋無結果，請更換搜尋關鍵字</h4>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default Signed;

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import OuterLayout from './components/Layout/OuterLayout';
import LoginForm from './components/AuthManagement/LoginForm';
import ForgotPasswordForm from './components/AuthManagement/ForgotPasswordForm';
import ResetPasswordForm from './components/AuthManagement/ResetPasswordForm';
import SettingPasswordForm from './components/AuthManagement/SettingPasswordForm';
import PlatformLayout from './components/Layout/PlatformLayout';

import Admin from './components/AdminDashboard/Admin';
import CompanySystem from './components/AdminDashboard/CompanySystem';
import Workspace from './components/AdminWorkspace/Workspace';
import Signed from './components/AdminWorkspace/Signed';
import Dashboard from './components/ExecutiveDashboard/Business';
import Payment from './components/ExecutiveDashboard/Payment';
import PlanManagement from './components/ExecutiveDashboard/PlanManagement';
import LifeCycle from './components/TdpDashboard/LifeCycle';
import TdpDashboard from './components/TdpDashboard/Dashboard';
import QuestionPreview from './components/TdpDashboard/QuestionPreview';
import PAEISettings from './components/TdpDashboard/PAEISettings';
import RecommendCorse from './components/TdpDashboard/PAEI/RecommendCorse';
import PersonalTalent from './components/TdpDashboard/PersonalTalent';
import ASK from './components/TdpDashboard/ASK/Lists';
import TdpDashboardOverview from './components/TdpDashboard/DashboardOverview';
import Enterprise from './components/TdpDashboard/Enterprise';

import 'react-loading-skeleton/dist/skeleton.css';

import { getAuth, selectPermissionSettings } from './store/slice/permissionSettings';

const AuthManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRoot = () => {
    if (
      document.location.pathname.includes('/login') ||
      document.location.pathname.includes('/register') ||
      document.location.pathname.includes('/forgot-password') ||
      document.location.pathname.includes('/reset-password') ||
      document.location.pathname.includes('/confirm-email') ||
      document.location.pathname.includes('/setting-password')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const serviceAreaElm = document.getElementById('service-area');
  if (!isRoot()) serviceAreaElm.style['display'] = 'block';

  const { auth } = useSelector(selectPermissionSettings);
  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (cookies.token) {
      dispatch(getAuth());
    } else if (!cookies.token && isRoot() === false) {
      navigate('/');
    }
  }, [cookies, dispatch, navigate]);

  return (
    <>
      <Routes>
        <Route element={<OuterLayout />}>
          <Route path='/' element={<LoginForm />} />
          <Route path='login' element={<LoginForm />} />
          <Route path='forgot-password' element={<ForgotPasswordForm />} />
          <Route path='reset-password' element={<ResetPasswordForm />} />
          <Route path='setting-password' element={<SettingPasswordForm />} />
        </Route>
      </Routes>

      {auth?.roles.find((item) => item === 'workspace.admin') && (
        <Routes>
          <Route element={<PlatformLayout />}>
            <Route path='/admin/:id' element={<Admin />} />
            <Route path='/admin/company-system' element={<CompanySystem />} />
            <Route path='/admin/workspace' element={<Workspace />} />
          </Route>
        </Routes>
      )}

      {auth?.roles.find((item) => item === 'workspace.user') && (
        <Routes>
          <Route element={<PlatformLayout />}>
            <Route path='/dashboard/business/:id' element={<Dashboard />} />
            <Route path='/dashboard/plan-management/:id' element={<PlanManagement />} />
            <Route path='/dashboard/payment/:id' element={<Payment />} />
            <Route path='/admin/workspace' element={<Workspace />} />
            <Route path='/admin/signed' element={<Signed />} />
          </Route>
        </Routes>
      )}

      {/* ROLES : TDP */}
      <Routes>
        <Route element={<PlatformLayout />}>
          <Route path='/dashboard/tdp/menu' element={<TdpDashboard />} />
          <Route path='/dashboard/tdp/menu/:id' element={<TdpDashboardOverview />} />
          <Route path='/dashboard/tdp/life-cycle' element={<LifeCycle />} />
          <Route path='/dashboard/tdp/life-cycle/question-preview' element={<QuestionPreview />}></Route>
          <Route path='/dashboard/tdp/PAEI-setting/:reference' element={<PAEISettings />}></Route>
          <Route path='/dashboard/tdp/PAEI-setting/recommend-corse' element={<RecommendCorse />}></Route>
          <Route path='/dashboard/tdp/personal-talent' element={<PersonalTalent />}></Route>
          <Route path='/dashboard/tdp/ask/:reference' element={<ASK />}></Route>
          <Route path='/dashboard/tdp/enterprise' element={<Enterprise />} />
        </Route>
      </Routes>
    </>
  );
};

export default AuthManagement;
